import Vue from 'vue'
import Vuex from 'vuex'

import modpack from './modpack'
import category from './category'
import server from './server'
import monitoring from './monitoring'
import shop from './shop'
import cart from './cart'

Vue.use(Vuex);

export const store = new Vuex.Store({
	modules: {
		modpack,
		category,
		server,
		monitoring,
		shop,
		cart,
	}
})
