const setList = ({commit}, payload) => {
	commit('SET_LIST', payload);
};

const setOne = ({commit}, payload) => {
	commit('SET_ONE', payload);
};

const removeOne = ({commit}, payload) => {
	commit('REMOVE_ONE', payload);
};

const setSelected = ({commit}, payload) => {
	commit('SET_SELECTED', payload);
};

export default {
	setList,
	setOne,
	removeOne,
	setSelected,
}
