const setHighestOnline = ({commit}, payload) => {
	commit('SET_HIGHEST_ONLINE', payload);
};

const setTotalAccounts = ({commit}, payload) => {
	commit('SET_TOTAL_ACCOUNTS', payload);
};

export default {
	setHighestOnline,
	setTotalAccounts,
}
