import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	highestOnline: 0,
	totalAccounts: 0,
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations,
};
