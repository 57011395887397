import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const router = new VueRouter({
	routes: [
		{
			path: '/',
			redirect: '/main'
		},
		{
			path: '/main',
			name: 'main',
			component: () => import('../views/MainPage')
		},
		{
			path: '/download',
			name: 'download',
			component: () => import('../views/DownloadPage')
		},
		{
			path: '/registration',
			redirect: '/registration/unknown'
		},
		{
			path: '/registration/:code',
			name: 'registration',
			component: () => import('../views/ConfirmRegistrationPage')
		},
		{
			path: '/restore',
			redirect: '/restore/unknown'
		},
		{
			path: '/restore/:code',
			name: 'restore',
			component: () => import('../views/ConfirmRestorePage')
		},
		{
			path: '/hardware',
			redirect: '/hardware/unknown'
		},
		{
			path: '/hardware/:code',
			name: 'hardware',
			component: () => import('../views/ConfirmHardwarePage')
		},
		{
			path: '/cart',
			name: 'cart',
			component: () => import('../views/CartPage')
		},
		{
			path: '/payment/success',
			name: 'paymentSuccess',
			component: () => import('../views/PaymentSuccessPage')
		},
		{
			path: '/payment/fail',
			name: 'paymentFail',
			component: () => import('../views/PaymentFailPage')
		},
		{
			path: '/offer',
			name: 'Offer',
			component: () => import('../views/OfferPage')
		},
	],

	scrollBehavior(to, from, savedPosition) {
		document.body.scrollTop = 0;
	}
});
