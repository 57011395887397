import Vue from 'vue';
import App from './App.vue';
import {router} from './router';
import {store} from "./store";

import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.config.devtools = true;

(async () => {
	while (typeof window.init !== 'function')
		await new Promise(r => setTimeout(r, 10));

	new Vue({
		router,
		store,
		i18n,
		render: h => h(App),
		mounted() {
			window.app = this.$children[0];
			window.init();
			this.$forceUpdate();
		}
	}).$mount('#app');
})();
