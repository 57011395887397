const setList = ({commit}, payload) => {
	commit('SET_LIST', payload);
};

const set = ({commit}, payload) => {
	commit('SET', payload);
};

const remove = ({commit}, payload) => {
	commit('REMOVE', payload);
};

const clear = ({commit}, payload) => {
	commit('CLEAR', payload);
};

const setSelected = ({commit}, payload) => {
	commit('SET_SELECTED', payload);
};

export default {
	setList,
	set,
	remove,
	clear,
	setSelected,
}
