const SET_LIST = (state, value) => {
	state.list = value;
};

const SET_ONE = (state, value) => {
	app.$set(state.list, value.id, value);
};

const REMOVE_ONE = (state, value) => {
	app.$delete(state.list, value);
};

const SET_SELECTED = (state, value) => {
	state.selected = value;
};

export default {
	SET_LIST,
	SET_ONE,
	REMOVE_ONE,
	SET_SELECTED,
};
