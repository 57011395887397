const setList = ({commit}, payload) => {
	commit('SET_LIST', payload);
};

const addToCart = ({commit}, payload) => {
	commit('ADD_TO_CART', payload);
};

const removeFromCart = ({commit}, payload) => {
	commit('REMOVE_FROM_CART', payload);
};

const clear = ({commit}, payload) => {
	commit('CLEAR', payload);
};

export default {
	setList,
	addToCart,
	removeFromCart,
	clear,
}
