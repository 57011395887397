<template>
	<div>
		<router-view></router-view>

		<div class="lang-selector">
			<div class="lang-current" @click="langSelectorOpened = !langSelectorOpened">{{ lang.toUpperCase() }}</div>

			<div class="lang-select" :class="{ opened: !!langSelectorOpened }">
				<div v-for="lang of langs" :value="lang" @click="changeLanguage">{{ lang.toUpperCase() }}</div>
			</div>
		</div>

		<div class="modal" ref="modal" style="display: none">
			<div class="modal__box">
				<div class="modal__close" @click="hideModal">
					<em class="fa fa-times"></em>
				</div>
				<div class="modal__container" v-html="modalContent"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "App",
		computed: {
			langs() {
				return this.supportedLangs.filter(lang => lang !== this.lang);
			}
		},
		data: () => ({
			lang: 'ru',
			supportedLangs: ['ru', 'ua'],
			langSelectorOpened: false,

			modalContent: '',
		}),
		methods: {
			changeLanguage(e) {
				this.lang = e.target.getAttribute('value');
				localStorage.setItem('lang', this.lang);
				this.$i18n.locale = this.lang;

				this.langSelectorOpened = false;
			},

			showModal(content) {
				this.modalContent = content;
				$(this.$refs.modal).fadeIn(200);
			},

			hideModal() {
				$(this.$refs.modal).fadeOut(200);
			}
		},
		created() {
			this.lang = localStorage.getItem('lang') || 'ru';

			$('body').scroll(function () {
				if (window.innerWidth > 720) {
					let opacity = $('body').scrollTop() / 200;
					$('header').css({
						background: 'linear-gradient(to bottom, rgba(45, 28, 51, ' + opacity + ') 0%, rgba(27, 19, 30, ' + opacity + ') 100%)'
					});
				}
			});

			let cart;
			try {
				cart = JSON.parse(localStorage.getItem('cart'));
			} catch (e) {}

			if (!Array.isArray(cart))
				cart = [];

			this.$store.dispatch('cart/setList', cart || []);
		}
	}
</script>

<style scoped>
	.lang-selector {
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		z-index: 9999;

		width: 2rem;
		height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.lang-current {
		background: #97c514 linear-gradient(to top, #97c514 0%, #d2d92a 100%);
		border-radius: 50%;
		color: rgba(255, 255, 255, .7);
		font-size: 0.8rem;
		text-align: center;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		z-index: 2;
	}

	.lang-select {
		background: #97c514 linear-gradient(to top, #97c514 0%, #d2d92a 100%);
		border-radius: 1rem;
		overflow: hidden;
		position: absolute;
		height: 2rem;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		transition: all .3s;
	}

	.lang-select.opened {
		height: 4rem;
	}

	.lang-select div {
		width: 100%;
		height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		color: rgba(255, 255, 255, .7);
		font-size: 0.8rem;
		cursor: pointer;
	}

	.lang-select div:hover {
		background: rgba(255, 255, 255, .2);
	}

	.lang-selector select {
		-webkit-appearance: none;
  	-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		color: #fff;
		opacity: .7;
		font-size: 0.8rem;
	}
</style>

<style>
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Roboto', sans-serif;
		user-select: none;
		outline: none;
	}

	.wrapper {
		width: 90%;
	}

	html {
		font-size: 2.2vmin;
	}

	html, body {
		width: 100vw;
		height: 100%;
		background: #131313;
		overflow-x: hidden;
	}

	*::-webkit-scrollbar {
		width: 0.2em;
	}

	*::-webkit-scrollbar-thumb {
		background-color: #fff;
	}

	*::-webkit-scrollbar-track {
		background: rgba(255, 255, 255, .1);
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	img {
		image-rendering: auto;
		-ms-interpolation-mode: nearest-neighbor;
	}

	input {
		user-select: all;
	}

	.preloader {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #131313;
		z-index: 1000;
	}

	@keyframes spin {
		0% { transform: translate(-50%, -50%) rotate(45deg); }
		100% { transform: translate(-50%, -50%) rotate(405deg); }
	}

	.preloader::before {
		content: "";
		display: block;
		width: 5rem;
		height: 5rem;
		border-right: solid .1rem #feac08;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: spin 1s infinite;
	}

	.modal {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, .7);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 9999;
	}

	.modal__box {
		min-width: 20%;
		max-width: 60%;
		min-height: 15%;
		max-height: 90%;
		background: #471568;
		color: #fff;
		border-radius: .5rem;
		border: solid .1rem #ffac07;
		box-shadow: 0 0 .1rem .05rem #000 inset;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.modal__close {
		position: absolute;
		top: 0;
		right: 0;
		width: 1.5rem;
		height: 1.5rem;
		transform:
				scale(.9)
				translate(-.5rem, -55%);
		font-size: .8rem;
		color: #ffac07;
		background: #471568;
		border-radius: 50%;
		border: solid .1rem #ffac07;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.modal__container {
		display: flex;
		flex-direction: column;
	}

	.modal__title {
		padding: .4rem .8rem .2rem;
		margin: 0 auto;
		font-size: 1rem;
		display: inline-block;
		text-align: center;
		border-bottom: solid .15rem #ffac07;
	}

	.modal__content {
		margin: .6rem 1rem;
		font-size: .8rem;
		overflow: auto;
		display: flex;
		flex-wrap: wrap;
		max-height: 80vh;
	}

	.modal__content::-webkit-scrollbar {
		width: .2rem;
	}

	.modal__content::-webkit-scrollbar-thumb {
		background: #ffac07;
	}

	.modal__content h4 {
		margin-bottom: .4rem;
	}

	.modal__content ul {
		list-style: none;
		min-width: 10rem;
		margin-right: .2rem;
	}

	.modal__content li {
		position: relative;
		padding-right: 1rem;
		margin-bottom: .2rem;
	}

	.modal__content li img {
		width: 10rem;
	}

	.modal__content table {
		min-width: 50vw;
	}

	.modal__content table td {
		padding: 0 1rem;
		text-align: center;
		border-bottom: solid 0.1rem #fff;
	}

	.modal__content table tr:last-child td {
		border-bottom: none;
		padding: .5rem;
	}

	.table__buttons {
		width: 6rem;
	}

	.table__buttons > div {
		display: flex;
	}

	.table__buttons .button {
		margin-right: .2rem;
	}

	.modal__buttons {
		width: 100%;
		padding: 0 .5rem;
		display: flex;
		justify-content: center;
	}

	.modal__buttons .form__input {
		margin: .5rem .5rem;
		height: 1.8rem;
	}

	.modal__buttons .button {
		width: auto;
		padding: .8rem;
	}

	.button {
		width: 1.8rem;
		height: 1.8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(to top, #d23ba3 0%, #fde277 100%);
		border: 0;
		border-radius: 3rem;
		margin: .5rem 0 .5rem;
		color: #fff;
		text-transform: uppercase;
		font-size: .8rem;
		text-shadow: .05rem .05rem .05rem rgba(0, 0, 0, .5);
		cursor: pointer;
		transition: color .2s;
		/*box-shadow:*/
		/*		0 .3rem .6rem -.1rem rgba(0, 0, 0, .4),*/
		/*		0 .3rem .45rem 0 rgba(147, 179, 25, 0.1),*/
		/*		0 0 .1rem 0 #fff inset;*/
		position: relative;
		overflow: hidden;
	}

	.button::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(255, 255, 255, .1);
		opacity: 0;
		transition: all .3s;
	}

	.button:hover::before {
		opacity: 1;
	}

	.form__error {
		color: #d438a3;
		font-size: 0.8rem;
		text-shadow: .05rem .05rem .05rem rgba(0, 0, 0, .5);
		margin-bottom: 2.5rem;
	}

	.form__input {
		width: 10rem;
		height: 2rem;
		background: rgba(0, 0, 0, .2);
		border: solid .15rem #6e42ff;
		border-radius: 2rem;
		margin-bottom: 1rem;
		position: relative;
		box-shadow:
				0 .3rem 6rem -.1rem rgba(0, 0, 0, .4),
				0 .3rem 5rem 0 rgba(114, 235, 255, 0.1),
				0 0 .1rem 0 #000 inset;
	}

	.form__input::before,
	.form__input::after {
		content: "";
		width: 8rem;
		height: .08rem;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background: linear-gradient(to right, #6e42ff 0%, #72e8ff 40%, #72e8ff 60%, #6e42ff 100%);
	}

	.form__input::before {
		top: -.1rem;
	}

	.form__input::after {
		bottom: -.1rem;
	}

	.form__input input {
		background: none;
		border: none;
		width: 100%;
		height: 100%;
		padding: .75rem;
		text-align: center;
		font-size: .75rem;
		color: #fff;
	}

	.form__input input::-webkit-input-placeholder {
		text-transform: uppercase;
		font-size: .75rem;
		text-shadow: .05rem .05rem .05rem rgba(0, 0, 0, .5);
		color: #fff;
	}

	.form__button {
		width: 10rem;
		padding: 0 .8rem;
	}

	.green {
		color: #669035;
	}

	.red {
		color: #903935;
	}
</style>
