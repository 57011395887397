const SET_HIGHEST_ONLINE = (state, value) => {
	state.highestOnline = value;
};

const SET_TOTAL_ACCOUNTS = (state, value) => {
	state.totalAccounts = value;
};

export default {
	SET_HIGHEST_ONLINE,
	SET_TOTAL_ACCOUNTS,
};
