const SET_LIST = (state, value) => {
	state.list = value;
};

const SET_ONE = (state, value) => {
	app.$set(state.list, value.id, value);
};

const SET_MAX_PLAYERS = (state, {id, value}) => {
	app.$set(state.list[id], 'maxPlayers', value);
};

const SET_AVAILABLE = (state, {id, value}) => {
	app.$set(state.list[id], 'available', value);
};

const ADD_PLAYER = (state, {id, login}) => {
	state.list[id].players.push(login);
};

const REMOVE_PLAYER = (state, {id, login}) => {
	state.list[id].players.splice(state.list[id].players.indexOf(login), 1);
};

const CLEAR_PLAYERS = (state, {id}) => {
	state.list[id].players = [];
};

export default {
	SET_LIST,
	SET_ONE,
	SET_MAX_PLAYERS,
	SET_AVAILABLE,
	ADD_PLAYER,
	REMOVE_PLAYER,
	CLEAR_PLAYERS,
};
