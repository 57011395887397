const setList = ({commit}, payload) => {
	commit('SET_LIST', payload);
};

const setOne = ({commit}, payload) => {
	commit('SET_ONE', payload);
};

const setMaxPlayers = ({commit}, payload) => {
	commit('SET_MAX_PLAYERS', payload);
};

const setAvailable = ({commit}, payload) => {
	commit('SET_AVAILABLE', payload);
};

const addPlayer = ({commit}, payload) => {
	commit('ADD_PLAYER', payload);
};

const removePlayer = ({commit}, payload) => {
	commit('REMOVE_PLAYER', payload);
};

const clearPlayers = ({commit}, payload) => {
	commit('CLEAR_PLAYERS', payload);
};

export default {
	setList,
	setOne,
	setMaxPlayers,
	setAvailable,
	addPlayer,
	removePlayer,
	clearPlayers,
}
