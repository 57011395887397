const SET_LIST = (state, value) => {
	state.list = value;
	localStorage.setItem('cart', JSON.stringify(state.list));
};

const ADD_TO_CART = (state, value) => {
	state.list.push(value);
	localStorage.setItem('cart', JSON.stringify(state.list));
};

const REMOVE_FROM_CART = (state, value) => {
	const index = state.list.indexOf(value);
	if (index !== -1) {
		state.list.splice(index, 1);
		localStorage.setItem('cart', JSON.stringify(state.list));
	}
};

const CLEAR = (state, value) => {
	state.list = [];
	localStorage.setItem('cart', JSON.stringify(state.list));
};

export default {
	SET_LIST,
	ADD_TO_CART,
	REMOVE_FROM_CART,
	CLEAR,
};
